<div class="modal-header">
    <h4 class="modal-title">New Update Available!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <h5>{{updateData.updateTitle}}</h5>
    <pre class="update-body" [innerHtml]="updateData.updateBody | safeHtml"></pre>
</div>

<div class="modal-footer">
  <a class="btn btn-icon" [href]="updateUrl" target="_blank" rel="noopener noreferrer">How to Update</a>
  <button type="button" class="btn {{updateData.isDocker ? 'btn-primary' : 'btn-secondary'}}" (click)="close()">Close</button>
  <a *ngIf="!updateData.isDocker" href="{{updateData.updateUrl}}" class="btn btn-primary" target="_blank" rel="noopener noreferrer" (click)="close()">Download</a>
</div>
